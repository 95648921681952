import * as React from "react"
import { Container, Box, Text, Section, Flex, Heading,PlatformHeading, PlatformSubhead } from "../ui"
import { StaticImage } from "gatsby-plugin-image"
export default function Contact_hero(props) {
    return (
        <>
            <Section  style={{background: " linear-gradient(280.25deg, #60A2FE 6.83%, #4847ED 42.13%, #8C27D7 73.52%)",color: "#fff",height:"50vh",position:"relative"}}>
                <Container width="tight" >
                    <Flex gap={4} variant="responsive">
                        <Box>
                            <PlatformHeading>
                            Contact us
                            </PlatformHeading>
                            <PlatformSubhead>
                            Upon receiving your message, we will contact you within 24 hours.
                            </PlatformSubhead>
                        </Box>
                    </Flex>

                </Container>
            </Section>
          
        </>
    )
}
