import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo";
import ContactUs from '../components/contact-us.js'
import Our_team_hero from "../components/our_team/our_tem_hero"
import Contact_hero from "../components/Contact_us/contact_hero";
import Contact_form from "../components/Contact_us/contact_form";


const Associates = () => (
  <Layout navName="Contact">
    <Seo title="Contact" />
    <Contact_hero/>
    {/* <Our_team_hero/> */}
    <Contact_form/>
  </Layout>
)

export default Associates
