import * as React from "react"
import { Container, Box, Text, Section, Flex, Subhead,Heading, PlatformHeading, PlatformSubhead } from "../ui"
import { StaticImage } from "gatsby-plugin-image"
import { about_hero, about_hero_img } from "../ui.css"
export default function Our_team_hero(props) {
    return (
        <>
        <Section className={about_hero}>
        <Container  width="tight" >
            <Flex gap={4} variant="responsive">
                <Box>
                <PlatformHeading>Our Team</PlatformHeading>
          <PlatformSubhead> The best platform for commerce skilling  job enhancement.</PlatformSubhead>
         
                </Box>
            </Flex>
         
        </Container>
      </Section>
      <Section></Section>
    
          <Container className={about_hero_img}>
          <StaticImage src='../../images/about_images/team_img.png' alt="team_img" />
          </Container>
     
      </>
    )
}
