import { StaticImage } from 'gatsby-plugin-image';
import React, { useEffect, useState } from 'react';
import { BsTelephoneFill } from 'react-icons/bs'
import { AiOutlineMail, AiOutlineTwitter } from 'react-icons/ai'
import { MdOutlineLocationOn } from 'react-icons/md'
import axios from '../../helpers/axios-config'
import {
    Container, Section, Flex, Box, Text, SubheadSmall, Button,
    ContactCard, ContactCardHeader, ContactCardBody, FlexList, MediumBoldText, Card
} from '../ui';
import { contactButton, inputTextBox, contact_form_card, contact_title, form } from '../ui.css';
import { object } from 'prop-types';

const Contact_form = () => {
    const intialvalues = {
        Firstname: "",
        LastName: "",
        PhoneNumber: "",
        Email:"",
        Message: ""

    }
    const [formvalues, setFormvalues] = useState("intialvalues")
    const [formErrors, setFormErrors] = useState({})
    const [issubmit, setIssubmit] = useState(false)
    const hanlechange = (e) => {
        const { name, value } = e.target
        setFormvalues({ ...formvalues, [name]: value });
        setIssubmit(false)
    }

    const submithandler=(e)=>{
        e.preventDefault()
        setFormErrors(validate(formvalues))

        if(formvalues.Firstname !=="" && formvalues.LastName!=="" && formvalues.PhoneNumber!=="" &&formvalues.Email!=="" && formvalues.Message!=="" ){
        var formData={name:formvalues.Firstname, phone:formvalues.PhoneNumber, email:formvalues.Email,message:formvalues.Message}

        axios.post('/contact',formData).then((response)=>{
                setIssubmit(true)
                setFormvalues(response.data)
                setFormvalues(intialvalues);
        }).catch((error)=>{
            if(error){
                console.log(error)
            }
            else{
                alert("Please fill the all feilds")
            }
        })
        }
    }
    useEffect(() => {
        if (Object.keys(formErrors).length === 0 && issubmit) {
        }
    }, [formErrors])
    const validate=(values)=>{
        const errors={}
        const regex = /^[^s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        const regexPhone = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

        if(!values.Firstname){
            errors.Firstname="Name is Required"
        }
        if(!values.LastName){
            errors.LastName="LastName is required";
        }
        if (!values.Email){
            errors.Email="Email is required";
        }
        else if(regex.test(values.Email)){
            errors.Email="This is not a valid format"
        }
        if(!values.PhoneNumber){
            errors.PhoneNumber="PhoneNumber is require"
        }
        else if(regexPhone.test(values.PhoneNumber)){
            errors.PhoneNumber="PhoneNumber is not valid format"
        }
        if(!values.Message){
            errors.Message="Message is required"
        }
        return errors;
    }



    return (
        <Section style={{ background: '#fff', clipPath: "polygon(0 0, 100% 0, 100% 100%, 0px 100%)" }} >
            <Container >

                <Flex gap={1} variant="responsive">
                    <Box>
                        <Flex gap={1} variant="responsive">
                            <Box>
                                <Card className={contact_form_card}>

                                    <FlexList gap={3} variant="responsive">
                                        <li><div><BsTelephoneFill /></div></li>
                                        <li></li>
                                        <li>
                                            <div className={contact_title}>PhoneNumber</div>
                                            <div style={{ whiteSpace: "nowrap" }}>+91 7019124511</div>
                                        </li>
                                    </FlexList>

                                </Card>
                            </Box>
                            <Box></Box>
                        </Flex>
                        <Flex gap={1} variant="responsive">
                            <Box>
                                <Card className={contact_form_card}>

                                    <FlexList gap={3} variant="responsive">
                                        <li><div><AiOutlineMail /></div></li>
                                        <li></li>
                                        <li>
                                            <div className={contact_title}>Email Address</div>
                                            <div style={{ whiteSpace: "nowrap" }}>info@BizzLab.com</div>
                                        </li>
                                    </FlexList>

                                </Card>
                            </Box>
                            <Box></Box>
                        </Flex>
                        <Flex gap={1} variant="responsive">
                            <Box>
                                <Card className={contact_form_card}>

                                    <FlexList gap={3} variant="responsive">
                                        <li><div><AiOutlineTwitter /></div></li>
                                        <li></li>
                                        <li>
                                            <div className={contact_title}>Twitter Address</div>
                                            <div style={{ whiteSpace: "nowrap" }}>@BizzLab</div>
                                        </li>
                                    </FlexList>

                                </Card>
                            </Box>
                            <Box></Box>
                        </Flex>
                        <Flex gap={1} variant="responsive">
                            <Box>
                                <Card className={contact_form_card}>

                                    <FlexList gap={3} variant="responsive">
                                        <li><div><MdOutlineLocationOn /></div></li>
                                        <li></li>
                                        <li>
                                            <div className={contact_title}>Location</div>
                                            <div >No.44, South End Road, Above Axis Bank, Basavanagudi, Bengaluru, Karnataka, 560004</div>
                                        </li>
                                    </FlexList>

                                </Card>
                            </Box>
                            <Box></Box>
                        </Flex>
                    </Box>
                    <Box className={form}  >
                        <ContactCard>
                            <ContactCardHeader>
                                <Flex gap={1} variant="responsive">
                                    <FlexList>
                                        <li>
                                            <StaticImage src="../images/email_box.png" alt="email_box" />
                                        </li>
                                        <li>
                                            <MediumBoldText>We’re happy to answer Your questions and get you acquainted with BizzLab.</MediumBoldText>
                                        </li>
                                    </FlexList>
                                </Flex>
                            </ContactCardHeader>
                            <ContactCardBody>
                                <form onSubmit={submithandler}>
                                    <Flex gap={4} variant="responsive">
                                        <Box>
                                            <input type="text" className={inputTextBox}
                                                onChange={hanlechange}
                                                name="Firstname"
                                                value={formvalues.Firstname}
                                                placeholder='FirstName' />
                                                <p>{formErrors.Firstname}</p>
                      
                                        </Box>
                                        <Box>
                                            <input type="text" className={inputTextBox}
                                                onChange={hanlechange}
                                                value={formvalues.Lastname}
                                                name="Lastname"
                                                placeholder='LastName' />
                                                  <p>{formErrors.LastName}</p>
                                        </Box>
                                    </Flex>
                                    <Flex gap={4} variant="responsive">
                                        <Box>
                                            <input type="text" className={inputTextBox}
                                                onChange={hanlechange}
                                                name="Email"
                                                value={formvalues.Email}
                                                placeholder='Email' />
                                                  <p>{formErrors.Email}</p>

                                        </Box>
                                        <Box>
                                            <input type="number" className={inputTextBox}
                                                onChange={hanlechange}
                                                name='PhoneNumber'
                                                value={formvalues.PhoneNumber}
                                                placeholder='PhoneNumber' />
                                                <p>{formErrors.PhoneNumber}</p>
                                        </Box>
                                    </Flex>
                                    <br />
                                    <Flex gap={4} variant="responsive">
                                        <Box>
                                            <SubheadSmall style={{ color: '#081F32' }}>Optional</SubheadSmall>
                                            <textarea rows="6" className={inputTextBox}
                                                onChange={hanlechange}
                                                value={formvalues.Message}
                                                name="Message"
                                                placeholder='Message' />
                                                <p>{formErrors.Message}</p>
                                        </Box>
                                    </Flex>
                                    <button className={contactButton}>SUBMIT</button>
                                </form>
                            </ContactCardBody>
                        </ContactCard>
                    </Box>
                </Flex>



            </Container>
        </Section>
    )
}
export default Contact_form;